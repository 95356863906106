import { useTranslation } from "react-i18next";
import { useState } from "react";
import { SnackbarCloseReason, Snackbar, Alert, FormControl, MenuItem, Select } from "@mui/material";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { PopupModal } from "react-calendly";
import { Contact } from "../../services/serv";


const FormFreeDemo: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState<any>(false);
    const [color, setColor] = useState<any>();
    const [message, setMessage] = useState<any>();
    const [phone, setPhone] = useState<any>();
    const [formData, setFormData] = useState<any>({
        name: "",
        email: "",
        companyName: "",
        phone:"",
        NumberEmployees: ""
    });
    const [error, setError] = useState('');
    const [errors, setErrors] = useState<any>({});
    const [openCalend, setOpenCalend] = useState(false);

    const handleCloseCalend = () => setOpenCalend(false);


    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFormData((prev: any) => ({ ...prev, [name]: value }));
    };

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const validateForm = (): boolean => {
        const newErrors: any = {};

        if (!formData.name) {
            newErrors.name = 'Name is required';
        }

        if (!formData.companyName) {
            newErrors.companyName = 'Company Name is required';
        }

        if (!phone) {
            newErrors.phone = 'Phone number is required';
        }

        if (!formData.NumberEmployees) {
            newErrors.NumberEmployees = 'Number of employees is required';
        }

        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!/^[A-Za-z\._\-[0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/.test(formData.email)) {
            newErrors.email = 'Please enter your email in the correct format';
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const updatedFormData = {
            name: formData.name,
            company_email: formData.email,
            message: formData.companyName,
            phone: phone,
            NumberEmployees: formData.NumberEmployees
        };
        if (validateForm()) {
            (window as any).dataLayer = (window as any).dataLayer || [];
            (window as any).dataLayer.push({
                event: 'form_submit',
                formId: 'freeDemoForm',
                formClasses: 'section-contact form-free',
                formData: {
                    updatedFormData
                },
            });
            setErrors({})
            Contact(updatedFormData).then((resp) => {
                setOpenCalend(true);
                setColor("success");
                setOpen(true);
                setMessage("Congratulations. Your message has been sent successfully")
            }).catch((error) => {
                setOpenCalend(false);
                setColor("error");
                setOpen(true);
                setMessage("Error, please retry. Your message has not been sent")
            })
        }
    };

    return (<>
        <div className="section-contact form-free" >
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-6 form-group mb-3">
                            <label htmlFor="name" className="form-label">{t("Name*")}</label>
                            <input
                                type="text" id="name"
                                className={`form-control-input height-48`}
                                value={formData.name}
                                name="name"
                                onChange={handleChange} />
                            {errors.name && <span className="error">{t(`${errors.name}`)}</span>}
                        </div>
                        <div className="col-6 form-group mb-3">
                            <label htmlFor="companyName" className="form-label">{t("Company Name*")}</label>
                            <input
                                type="text" id="companyName"
                                className={`form-control-input height-48`}
                                value={formData.companyName}
                                name="companyName"
                                onChange={handleChange} />
                            {errors.companyName && <span className="error">{t(`${errors.companyName}`)}</span>}
                        </div>
                        <div className="col-md-6 form-group  mb-3 " >
                            <label htmlFor="Phone Number" className="form-label">{t("Phone Number")}*</label>
                            <PhoneInput
                                country={'ae'}
                                value={phone}
                                enableSearch={false}
                                onChange={(value: string) => setPhone(value)}
                                inputProps={{
                                    name: 'phone',
                                    required: true,
                                    autoFocus: true
                                }}
                                containerStyle={{
                                    height: "48px",
                                    fontSize: "14px",
                                    width: "100%",
                                    direction: "ltr"
                                }}
                                inputStyle={{
                                    height: "48px",
                                    width: "100%",
                                    fontSize: "14px",
                                    padding: "19px 19px 19px 43px",
                                    border: "0.5px solid #746AFF",
                                    borderRadius: "40px",
                                    fontFamily: 'Inter',
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "155%",
                                    letterSpacing: "0.03em",
                                    color: "#696969"
                                }}
                                buttonStyle={{
                                    borderTopLeftRadius: "40px",
                                    borderBottomLeftRadius: "40px",
                                    background: "#FFFFFF",
                                    border: "0.5px solid #746AFF",
                                }}
                            />
                            {errors.phone && <span className="error">{t(`${errors.phone}`)}</span>}
                        </div>
                        <div className="col-md-6 form-group mb-3">
                            <label htmlFor="Number of employees" className="form-label">{t("Number of employees*")}</label>
                            <FormControl fullWidth >
                                <Select
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Please Select' }}
                                    value={formData.NumberEmployees}
                                    name="NumberEmployees"
                                    className={`form-control-input height-48 `}
                                    onChange={handleChange}
                                    sx={{
                                        borderRadius: "40px",
                                        textAlign: "start"
                                    }}
                                >
                                    <MenuItem disabled >{t("Please Select")}</MenuItem>
                                    <MenuItem value={"1"}>1</MenuItem>
                                    <MenuItem value={"2 to 5"}>2 to 5</MenuItem>
                                    <MenuItem value={"6 to 10"}>6 to 10</MenuItem>
                                    <MenuItem value={"11 to 25"}>11 to 25</MenuItem>
                                    <MenuItem value={"26 to 50"}>26 to 50</MenuItem>
                                    <MenuItem value={"51 to 200"}>51 to 200</MenuItem>
                                    <MenuItem value={"201 to 1,000"}>201 to 1,000</MenuItem>
                                    <MenuItem value={"1,001 to 10,000"}>1,001 to 10,000</MenuItem>
                                    <MenuItem value={"10,001 to more"}>10,001 to more</MenuItem>

                                </Select>
                            </FormControl>
                            {errors.NumberEmployees && <span className="error">{t(`${errors.NumberEmployees}`)}</span>}
                        </div>
                    </div>
                    <div className="col-md-12 form-group mb-3">
                        <label htmlFor="email" className="form-label">{t("Email*")}</label>
                        <input
                            type="email"
                            className={`form-control-input height-48`}
                            value={formData.email}
                            name="email"
                            onChange={handleChange} />
                        {errors.email && <span className="error">{t(`${errors.email}`)}</span>}
                    </div>
                    <button className={`button ${formData.name && formData.companyName && formData.email && formData.NumberEmployees && phone ? "" : "disabled"}`} onClick={handleSubmit} style={{ fontSize: "16px" }}>{t("Get Your Free Demo")}</button>
                </div>
            </div>
        </div>

        {openCalend && (
            <PopupModal
                url="https://calendly.com/operations-coralytics/30min"
                pageSettings={{
                    backgroundColor: 'ffffff',
                    hideLandingPageDetails: true,
                    primaryColor: '573CFF',
                    textColor: '000000',
                }}
                prefill={{
                    email: formData.email,
                    name: formData.name,
                }}
                rootElement={document.getElementById("root") || document.body}
                onModalClose={handleCloseCalend}
                open={openCalend}
            />
        )}

        <Snackbar open={open} onClose={handleClose}>
            <Alert
                onClose={handleClose}
                severity={color}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>
    </>
    )

}
export default FormFreeDemo;