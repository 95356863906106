import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { PopupModal } from "react-calendly";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import FormFreeDemo from "../Real Estate Agencies/FormFreeDemo";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const GetStarted: React.FunctionComponent<{ Style: any, Value: any }> = (props) => {

    const [open, setOpen] = useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    return (<>
        <button className={props.Style} onClick={handleClickOpen} >{props.Value}</button>


        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth={true}
            maxWidth={"sm"}
        >
          
            <DialogContent >
                <div className="card-started">
                    <div className="row">
                        <div className="col-md-12">
                            <FormFreeDemo />
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>

    </>
    )

}
export default GetStarted;