import { useTranslation } from "react-i18next";
import { useState } from "react";
import { SendContact } from "../../services/serv";
import { SnackbarCloseReason, Snackbar, Alert } from "@mui/material";


const FormContact: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState<any>(false);
    const [color, setColor] = useState<any>();
    const [message, setMessage] = useState<any>();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [error, setError] = useState('');
    const [formError, setFormError] = useState({
        name: false,
        email: false,
        companyName: false,
        message: false
    });

    const validateEmail = (email: any) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setFormError({
            name: false,
            email: false,
            companyName: false,
            message: false
        });
        let valid = true;
        if (!name) {
            setFormError((prevState) => ({ ...prevState, name: true }));
            valid = false;
        }
        if (!message) {
            setFormError((prevState) => ({ ...prevState, message: true }));
            valid = false;
        }
        if (!email) {
            setFormError((prevState) => ({ ...prevState, email: true }));
            valid = false;
        } else if (!validateEmail(email)) {
            setError('Invalid email address');
            valid = false;
        }
        if (!companyName) {
            setFormError((prevState) => ({ ...prevState, companyName: true }));
            valid = false;
        }
        if (!valid) {
            return;
        }
        else {
            setError('');
            SendContact({ name : name, company_email : email, company_name : companyName, message : message }).then((resp) => {
                setColor("success");
                setOpen(true);
                setMessage("Congratulations. Your message has been sent successfully")
            }).catch((error) => {
                setColor("error");
                setOpen(true);
                setMessage("Error, please retry. Your message has not been sent")
            })
        }
        setError('');
    };

    return (
        <>
            <h1 className="title-contact mb-3">{t("Get Started")}</h1>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group mb-3">
                            <label htmlFor="name">{t("Name*")}</label>
                            <input
                                type="text" id="name"
                                className={`form-control-input ${formError.name ? 'error-border' : ''}`}
                                placeholder="Joh Doe"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            {formError.name && <p className="error">{t("Name is required")}</p>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mb-3">
                            <label htmlFor="email">{t("Email*")}</label>
                            <input
                                type="email" id="email"
                                className={`form-control-input ${formError.email ? 'error-border' : ''}`}
                                placeholder="Johndoe123@gmail.com"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {formError.email && <p className="error">{t("Email is required")}</p>}
                            {error && <p className="error">{error}</p>}
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group mb-3">
                        <label htmlFor="companyName">{t("Company Name*")}</label>
                        <input
                            type="text" id="companyName"
                            className={`form-control-input ${formError.companyName ? 'error-border' : ''}`}
                            placeholder="Coralytics Pvt Ltd"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                        />
                        {formError.companyName && <p className="error">{t("Company Name is required")}</p>}
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group mb-3">
                        <label htmlFor="companyName">{t("Message*")}</label>
                        <textarea
                           id="message"
                            className={`form-control-textarea ${formError.message ? 'error-border' : ''}`}
                            placeholder="Message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        {formError.message && <p className="error">{t("Message is required")}</p>}
                    </div>
                </div>
                <div className="form-group ">
                    <button type="submit" className="form-control-submit-button">{t("Book My Demo")}</button>
                </div>
            </form>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={color}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </>
    )

}
export default FormContact;